/// <reference path="../../shared_controllers/socketService.ts" />
/// <reference path="../../shared_controllers/util.ts" />
/// <reference path="../../app.ts" />
/// <reference path="../../shared_controllers/common_controllers.ts" />
/// <reference path="../../services/users.services.ts" />

class signinCtrl extends baseEntitiesCtrl{
    static $inject: Array<string> = ['$scope', '$location', 'socketService', 'ipCookie','toastr','$compile','$http','$sce'];
    loading: boolean = false;
    username: string;
    password: string;
    toastrObj :any;
    userServices:UsersServiceClass
    constructor(
        public $scope,
        public $location,
        public socketService: socketServiceFactory,
        public ipCookie,
        public toastr,
        public $compile,
        public $http,
        public $sce
       
    ) { 

        super($scope, $location, $compile, socketService);

        this.toastrObj = toastr;
        this.userServices = new UsersServiceClass(this.socketService.namespace.socket,this.socketService.namespace)

    }
    preventWhiteSpaces(event) {

        if (event.originalEvent.which == 32) {

            event.preventDefault();
        }
    }
    async dologin(asuser, withpassword) {
        clearError(this.$scope);
       var me=this;
        let username = this.username;
        let password = this.password;
        if (asuser) {
            username = asuser;
            password = withpassword;
        }
        try {
            var ipurl = "//api.ipify.org/";
            var trust = this.$sce.trustAsResourceUrl(ipurl);
            this.$http.jsonp(trust,{params: {format:'jsonp'}})
              .then(function(response) {
                 var h=response.data.ip;
                 me.genericServices.addLogRecord2(h);
            }).catch(function(response) {}) 
            this.loading = true;
            var user = await this.userServices.auth(username, password);
            this.ipCookie('jwt', user.token, {});
            this.userServices.settoken(user.token);
            this.userServices.settoken(user.token);


            
            this.ipCookie('user', user);
            this.socketService.setUser();
            this.socketService.user = user;
            if (user.roles.find(R=>R._id == attendedrobotRoleId))
            {
                this.toastrObj.error(GetErrorMessage('Access denied, Attended Robot'));
                this.$location.path("/signin")
            }
           else if(user.roles.find(R=>R._id ==adminsRoleId ) || user.roles.find(R=>R._id == rpaDesignerRoleId ) || user.roles.find(R=>R._id == robotsRoleId ) ||user.roles.find(R=>R._id == unattendedrobotRoleId )){
                this.$location.path("/projects_list")
            }else if(user.roles.find(R=>R._id == noderedUserRoleId ) || user.roles.find(R=>R._id == workflowUserRoleId )){
                this.$location.path("/workflows")
            }else{
                this.$location.path("/logsDetailsReport")
            }
            this.loading = false;
            /*
            var url = this.ipCookie('refere');

            if (url == null) {
                //this.$location.path("/main");
            } else {
                this.ipCookie.remove('refere', { path: '/' });
                this.ipCookie.remove('refere', {});
                this.$location.path(url);
            }
            */
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            //handleError(this.$scope, err);
            this.loading = false;
            this.toastrObj.error(GetErrorMessage(err));
        }
    };
    recoverpassword() {
        if (this.username != '') {
            this.$location.path("/recoverpassword/0/" + this.username);
        } else {
            this.$location.path("/recoverpassword/0");
        }
    };
}

class signoutCtrl {
    static $inject: Array<string> = ['$scope', '$location', 'socketService', 'ipCookie'];
    loading: boolean = false;
    username: string;
    password: string;
    userServices:UsersServiceClass;
    constructor(
        public $scope,
        public $location,
        public socketService: socketServiceFactory,
        public ipCookie

    ) {
        this.userServices = new UsersServiceClass(this.socketService.namespace.socket,this.socketService.namespace)
        this.ipCookie.remove('jwt', { path: '/' });
        this.ipCookie.remove('jwt', {});
        this.userServices.signout();
        this.userServices.signout();
        this.userServices.signout();
        this.$location.path("/signin");
    }
}

class recoverPasswordCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$routeParams', 'socketService','toastr'];
    loading: boolean = false;
    step: number;
    username: string;
    password: string;
    passwordconfirm: string;
    userServices:UsersServiceClass;
    toastrObj :any;
    constructor(
        public $scope,
        public $location,
        public $routeParams,
        public socketService: socketServiceFactory,
        public toastr
    ) {
        this.userServices = new UsersServiceClass(this.socketService.namespace.socket,this.socketService.namespace)
        this.toastrObj = toastr;
        this.step = this.$routeParams.step;
        this.username = this.$routeParams.param;
        socketService.onSignedin(() => {
        });

    }
    async process() {
        this.loading = true;
        try {
            if (this.step == 0) {
                await this.userServices.recoverpassword(this.username);
                this.loading = false;
                this.step = 1;
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            if (this.step == 2) {
            
                await this.userServices.resetpassword(this.username, this.password);
                this.loading = false;
                this.step = 3;
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    };
}